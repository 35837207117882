<template>
  <a-card :border="false">
    <div v-if="handle === 'detail'">
      <a-form-model layout="horizontal" :label-col="{ span: 2 }" :wrapper-col="{ span: 21 }">
        <a-form-model-item label="公司名称">{{ tableData.dealerName }}</a-form-model-item>
        <a-form-model-item label="公司地址">{{ tableData.province }}{{ tableData.city }}{{ tableData.county }}{{ tableData.address }}</a-form-model-item>
        <a-form-model-item label="注册资金">{{!tableData.fund ? '--' : tableData.fund}} 万元</a-form-model-item>
        <a-form-model-item label="成立年限">{{!tableData.years ? '--' : tableData.years}} 年</a-form-model-item>
        <a-form-model-item label="公司人数">{{!tableData.title ? '--' : tableData.title}} 人</a-form-model-item>
        <a-form-model-item label="营业执照">
          <span v-if="!tableData.businessLic">暂无</span>
          <span v-else>
            <ZoomMedia style="height: 20px" :value="tableData.businessLic"></ZoomMedia>
          </span>
        </a-form-model-item>
        <a-form-model-item label="授权证书">
          <span v-if="!tableData.authBook">暂无</span>
          <ZoomMedia v-else style="height: 20px" :value="tableData.authBook"></ZoomMedia>
        </a-form-model-item>
        <a-form-model-item label="公司logo">
          <span v-if="!tableData.companyLogo">暂无</span>
          <ZoomMedia v-else style="height: 20px" :value="tableData.companyLogo"></ZoomMedia>
        </a-form-model-item>
        <a-form-model-item label="公司标签">
          <span v-if="tableData.tagList.length <= 0">暂无</span>
          <div v-else>
            <template v-for="tag in tableData.tagList">
              <a-tag :key="tag" @close="() => handleClose(tag)">
                {{ tag }}
              </a-tag>
            </template>
          </div>
        </a-form-model-item>
        <a-form-model-item label="公司描述">{{ !tableData.describeMsg ? '--' : tableData.describeMsg }}</a-form-model-item>
        <a-form-model-item label="经营范围">{{ !tableData.intro ? '--' : tableData.intro }}</a-form-model-item>
        <a-form-model-item label="经营品牌">
          <span v-if="tableData.brandLogos.length <= 0">暂无</span>
          <a-tag v-else v-for="(item, index) in tableData.brandLogos" :key="index">{{item.brandName}}</a-tag>
        </a-form-model-item>
        <div class="footer-btns">
          <a-button v-if="isEdit" type="default" @click="onEdit">修改</a-button>
        </div>
      </a-form-model>
    </div>
    <div v-else>
      <a-form-model layout="horizontal" :label-col="{ span: 2 }" :wrapper-col="{ span: 9 }">
        <a-form-model-item label="公司名称">
          <a-input disabled v-model="tableData.dealerName" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="公司地址">
          <PCD
            ref="pcd"
            placeholder="请选择区域"
            :province.sync="tableData.province"
            :city.sync="tableData.city"
            :district.sync="tableData.county"
            :provinceId.sync="tableData.provinceId"
            :cityId.sync="tableData.cityId"
            :districtId.sync="tableData.countyId"
          >
          </PCD>
        </a-form-model-item>
        <a-form-model-item label="详细地址">
          <a-input v-model="tableData.address" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="注册资金">
            <a-input v-model="tableData.fund" addon-after="万元" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="成立年限">
            <a-input v-model="tableData.years"  addon-after="年" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="公司人数">
            <a-select placeholder="请选择公司人数" v-model="tableData.numberOfPeople" allowClear>
              <a-select-option :value="item.id" v-for="(item, index) in numberOfPeopleList" :key="index">{{item.title}}</a-select-option>
            </a-select>
        </a-form-model-item>
        <a-form-model-item label="营业执照">
          <QiniuUpload type="image" :value.sync="tableData.businessLic"></QiniuUpload>
        </a-form-model-item>
        <a-form-model-item label="授权证书">
          <QiniuUpload type="image" :value.sync="tableData.authBook"></QiniuUpload>
        </a-form-model-item>
        <a-form-model-item label="公司logo">
          <QiniuUpload type="image" :value.sync="tableData.companyLogo"></QiniuUpload>
        </a-form-model-item>
        <a-form-model-item label="公司标签">
          <div>
            <template v-for="tag in tableData.tagList">
              <a-tag :key="tag" closable @close="() => handleClose(tag)">
                {{ tag }}
              </a-tag>
            </template>
            <a-input
              v-if="inputVisible"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm"
              allowClear
            />
            <a-tag v-else style="background: #fff; borderstyle: dashed" @click="showInput">
              <a-icon type="plus" /> 新增标签
            </a-tag>
          </div>
        </a-form-model-item>
        <a-form-model-item label="公司描述">
          <a-textarea placeholder="请输入" v-model="tableData.describeMsg"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="经营范围">
          <a-textarea placeholder="请输入" v-model="tableData.intro"></a-textarea>
        </a-form-model-item>
        <div style="font-size: 17px; font-weight: bold;">经营品牌</div>
        <div class="line" v-for="(item, index) in brandLogos" :key="index">
          <a-form-model-item label="经营品牌">
            <a-input placeholder="请输入" v-model="item.brandName" allowClear></a-input>
          </a-form-model-item>
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="品牌logo" :label-col="{ span:4 }" :wrapper-col="{ span: 9 }">
                <QiniuUpload type="image" :value.sync="item.brandLogo"></QiniuUpload>
              </a-form-model-item>
            </a-col>
            <a-col :md="6" style="margin-top: 64px;">
              <a @click="onDeleteBrand(item)">删除</a>
            </a-col>
          </a-row>
        </div>
        <a-form-model-item>
          <a-button @click="onAddBrand()">新增品牌</a-button>
        </a-form-model-item>

        <div class="footer-btns">
          <a-button type="default" @click="onSubmit">保存</a-button>
          <a-button type="default" @click="onHome">返回</a-button>
        </div>
      </a-form-model>
    </div>
  </a-card>
</template>

<script>
import { listDealerCompanyInfo } from './api/DealerCompanyInfoApi'
import {checkPermission} from '@/utils/permissions'

export default {
  name: 'dealerCompanyInfo',
  data() {
    return {
      tableData: {},
      inputVisible: false,
      inputValue: '',
      handle: 'detail',
      numberOfPeopleList: [],
      brandLogos: [
        {
          brandLogo: '',
          brandName: '',
          companyId: ''
        }
      ],
      isEdit: checkPermission('company:info:edit'),
    }
  },

  methods: {
    /**
     * 获取表格数据
     */
    getData() {
      listDealerCompanyInfo().then((res) => {
        if (res.code == 200) {
          this.tableData = res.body
        }
      })
      this.axios.get('/api/base/system/dictionary/selectByCode/'+ '公司人数').then(res => {  // 获取字典,公司人数
        this.numberOfPeopleList = res.body
      })
    },

    // 点击新增标签时显示输入框
    showInput() {
      this.inputVisible = true
      this.$nextTick(function () {
        this.$refs.input.focus()
      })
    },

    // 删除标签
    handleClose(removedTag) {
      const tags = this.tableData.tagList.filter((tag) => tag !== removedTag)
      this.tableData.tagList = tags
    },

    // 监听当前标签
    handleInputChange(e) {
      this.inputValue = e.target.value
    },

    // 失去焦点时新增标签
    handleInputConfirm() {
      if (!this.inputValue) {
        return this.$message.warning('标签名不能为空')
      }
      this.axios.get(`/api/dealer/company/dealerCompanyInfo/tag/add?tagName=${this.inputValue}`).then((res) => {
        if (res.code == 200) {
          this.inputVisible = false
          // this.getData()
        }
      })
    },

    // 添加品牌
    onAddBrand() {
      this.brandLogos.push({
        brandLogo: '',
        brandName: '',
        companyId: '',
        id: ''
      })
    },

    // 删除品牌
    onDeleteBrand(item) {
      this.brandLogos.splice(
        this.brandLogos.findIndex((e) => {
          return e == item
        }),
        1
      )
    },

    // 编辑公司信息时回显
    onEdit() {
      this.handle = 'edit'
      this.$set(this.tableData, 'provinceId', this.tableData.provinceId)
      this.$set(this.tableData, 'province', this.tableData.province)
      this.$set(this.tableData, 'cityId', this.tableData.cityId)
      this.$set(this.tableData, 'city', this.tableData.city)
      this.$set(this.tableData, 'county', this.tableData.county)
      this.$set(this.tableData, 'countyId', this.tableData.countyId)
      this.brandLogos = this.tableData.brandLogos
      this.$nextTick(() => {
        this.$refs.pcd.setPCD()
      })
    },

    // 返回
    onHome() {
      this.handle = 'detail'
    },

    // 提交数据
    onSubmit() {

      this.brandLogos = this.brandLogos.map(e => {
        return {
          // companyId: this.$store.getters.userInfo.id,
          companyId: this.tableData.id,
          brandLogo: e.brandLogo,
          brandName: e.brandName,
          id: e.id
        }
      })
      this.brandLogos = this.brandLogos.filter(e => e.brandName != '' && e.brandLogo != '')
      this.tableData.brandLogos = this.brandLogos
      this.axios.post(`/api/dealer/company/dealerCompanyInfo/edit`, this.tableData).then((res) => {
        if (res.code == 200) {
          this.handle = 'detail'
          this.getData()
          this.$message.success(res.message)
        }
      })
    },
  },
  created() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
.ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.ant-form-item {
  margin-bottom: 10px;
}
.footer-btns {
  text-align: right;
  margin-top: 20px;
}
.line {
  // margin-top: 20px;
  padding-top: 20px;
  // border-top: 1px solid #E9E9E9;
  border-bottom: 1px solid #E9E9E9;
}
</style>
